@import '../../../../styles/customMediaQueries.css';

.root {
  @media (--viewportMedium) {
    padding: 0 60px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 120px;
  }

  @media (--viewportXLarge) {
    padding: 0 calc((100% - 1440px) / 2);
  }
}

.root > div {
  padding: 12px 0;

  @media (--viewportLargeWithPaddings) {
    padding: 24px 12px;
  }
}

.baseColumn {
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(2, calc((100% - 12px) / 2));
  gap: 12px;
  margin: 0 auto;
}

.root div[class^='SectionBuilder_blockContainer'] {
  padding: 0 12px;

  @media (--viewportLargeWithPaddings) {
    padding: 0 20px;
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 24px) / 4));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(6, calc((100% - 5 * 24px) / 6));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 12px) / 4));
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(6, calc((100% - 5 * 12px) / 6));
  }

  @media (--viewportXLarge) {
    gap: 24px;
    grid-template-columns: repeat(6, calc((100% - 5 * 24px) / 6));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
