@import '../../../../styles/customMediaQueries.css';

.root {
  @media (--viewportMedium) {
    padding: 0 60px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 120px;
  }

  @media (--viewportXLarge) {
    padding: 0 calc((100% - 1440px) / 2);
  }
}

.baseColumn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.fiveColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    gap: 25px;
    grid-template-columns: repeat(4, calc((100% - 3 * 25px) / 4));
  }

  @media (--viewportLargeWithPaddings) {
    gap: 50px;
    grid-template-columns: repeat(5, calc((100% - 4 * 50px) / 5));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.root div[class^='BlockDefault_text'] h3 {
  height: 40px;
}

.root ul[class^='List_ul'] {
  list-style-type: none;
  padding-inline-start: 0;
}

.root ul[class^='List_ul'] li {
  line-height: 1em;
}

.root ul[class^='List_ul'] li a {
  color: var(--colorBlack);
}
