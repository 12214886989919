@import '../../../../styles/customMediaQueries.css';

.root {
  @media (--viewportMedium) {
    padding: 0 60px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 120px;
  }

  @media (--viewportXLarge) {
    padding: 0 calc((100% - 1440px) / 2);
  }
}

.root > div[class^='SectionContainer_sectionContent'] {
  padding: 24px 0;
}

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  composes: baseColumn;
}

.root .featuresMain:first-child {
  max-width: unset;
  width: 100%;
  padding: 0 32px;

  @media (--viewportLargeWithPaddings) {
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-auto-flow: dense;
  gap: 0;

  @media (--viewportMedium) {
    gap: 32px;
    flex-direction: row-reverse;
  }

  @media (--viewportLargeWithPaddings) {
    gap: 64px;
  }

  &:nth-child(even) {
    @media (--viewportMedium) {
      flex-direction: row;
    }
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.root .block div[class^='BlockFeatureStyle1_text'] {
  margin: 0;
  padding: 60px 0;
  flex: 1;

  @media (--viewportLargeWithPaddings) {
    padding: 100px 0;
    flex: 2;
  }
}

.root .block div[class^='BlockFeatureStyle1_media'] {
  height: 100%;
  flex: 1;

  @media (--viewportLargeWithPaddings) {
    flex: 3;
  }
}

.root .block div[class^='BlockFeatureStyle1_text'] h3 {
  font-size: 30px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--colorBlack);

  @media (--viewportLargeWithPaddings) {
    font-size: 36px;
  }
}

.root .block div[class^='BlockFeatureStyle1_text'] p {
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  color: var(--colorBlack);
}
