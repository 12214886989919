@import '../../../../styles/customMediaQueries.css';

.root {
  @media (--viewportMedium) {
    padding: 0 60px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 120px;
  }

  @media (--viewportXLarge) {
    padding: 0 calc((100% - 1440px) / 2);
  }
}

.root div[class^='SectionContainer_sectionContent'] {
  padding: 32px 0;
}

.headerContent {
  display: flex;
  justify-content: space-between;
}

.headerContent a {
  display: flex;
  margin: 0;
  padding-bottom: 7px;
  align-items: center;
}

.listContainer h3 {
  font-size: 32px;
  font-weight: var(--fontWeightSemiBold);
}

.baseColumn {
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin: 0 auto;
  padding: 0px;
}

.containers {
  composes: baseColumn;

  @media (--viewportMedium) {
    gap: 50px;
  }

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: repeat(2, calc((100% - 50px) / 2));
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 10px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 10px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  grid-template-columns: repeat(2, calc((100% - 1 * 10px) / 2));

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 10px) / 4));
  }
}

.listContainer .noSidePaddings {
  padding: 0;
}

.moreBtn {
  font-size: 16px;
  font-weight: 700;
}
