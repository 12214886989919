@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    padding: 50px 60px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 120px;
  }

  @media (--viewportXLarge) {
    padding: 0 calc((100% - 1440px) / 2);
  }
}

.root div[class^='SectionContainer_sectionContent'] {
  width: 100%;
}

.root .heroContent {
  justify-content: flex-start;
  max-width: 1440px;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.marketplaceName {
  font-weight: var(--fontWeightThin);
  color: var(--colorWhite);
  font-size: 32px;
  line-height: 2em;
}

.marketplaceNameHighlight {
  font-weight: var(--fontWeightSemiBold);
}

.heroContent h1 {
  width: 100%;
  font-weight: var(--fontWeightMedium);
  font-size: 30px;
  line-height: 1.2em;
  margin: 0;
  font-family: var(--fontFamily);

  @media (--viewportMedium) {
    font-size: 35px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 45px;
  }
}

.heroContent h1 strong {
  font-weight: var(--fontWeightSemiBold);
}

.root .heroContent p {
  font-family: Helvetica, sans-serif;
  width: 100%;
  max-width: unset;
  text-align: left;
  font-size: 20px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.6em;
  letter-spacing: -0.5px;
  margin-top: 20px;
}

.heroContent a {
  width: 320px;
  height: 60px;
  justify-self: self-start;
  margin-top: 30px;
  border-radius: 8px;
  background-color: var(--colorWhite);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--colorGrey700) !important;
  font-size: 24px;
  font-weight: var(--fontWeightRegular);
  box-shadow: none;

  &:hover {
    background-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    height: 70px;
    font-size: 24px;
  }
}
