@import '../../../../styles/customMediaQueries.css';

.root {
  margin-bottom: 20px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 120px;
  }

  @media (--viewportXLarge) {
    padding: 0 calc((100% - 1440px) / 2);
  }
}

.root div[class^='SectionContainer_sectionContent'] {
  max-width: 1440px;
  margin: auto;
  padding: 40px 0 0;

  @media (--viewportLargeWithPaddings) {
    padding: 40px 32px 0;
  }

  @media (--viewportXLarge) {
    padding: 40px 0 0;
  }
}

.banners {
  display: grid;
  gap: 0;

  @media (--viewportMedium) {
    grid-template-columns: 3fr 4fr;
  }

  @media (--viewportXLarge) {
    padding: 0 32px;
  }
}

.bannerWrapper {
  position: relative;
}

.bannerText {
  z-index: 2;
  width: 100%;
  padding: 20px 30px;
  background-color: var(--marketplaceColor);
  font-size: 18px;
  letter-spacing: 1.2px;
  color: var(--colorWhite);
  font-weight: var(--fontWeightRegular);

  @media (--viewportLarge) {
    position: absolute;
    right: 0;
    bottom: 14px;
    max-width: 400px;
  }
}

.banner {
  height: 100%;
}

.banner img {
  border-radius: 0;
}
