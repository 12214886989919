.root {
  background-color: #ff5300;
  padding: 10px 0 24px;
  border-radius: 8px;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.text h3 {
  font-size: 24px;
  letter-spacing: -0.52px;
  font-weight: var(--fontWeightMedium);
  color: var(--colorWhite);
  text-align: center;
  margin: auto;
}
