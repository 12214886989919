@import '../../../../styles/customMediaQueries.css';

.root {
  padding: 0 0 30px;

  @media (--viewportMedium) {
    padding: 30px 60px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0px 120px 30px;
  }

  @media (--viewportXLarge) {
    padding: 0px calc((100% - 1440px) / 2) 30px;
  }
}

.root div[class^='SectionContainer_sectionContent'] {
  padding: 0 32px;
}

.root header {
  justify-content: flex-start;
  max-width: unset;
  margin: 0;
  padding: 0;
}

.root header h2 {
  font-size: 32px;
  font-weight: var(--fontWeightThin);
  text-align: left;
  line-height: 64px;
  max-width: unset;
}

.root header h2 strong {
  font-weight: var(--fontWeightSemiBold);
}

.blocks {
  display: grid;
  gap: 25px;
  margin-top: 20px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.block {
  display: flex;
  gap: 15px;
}

.blockIndex {
  font-size: 80px;
  line-height: 1em;
  font-weight: var(--fontWeightThin);
  color: var(--colorWhite);

  @media (--viewportMedium) {
    font-size: 100px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 140px;
  }
}

.blockContent {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportLargeWithPaddings) {
    display: block;
  }
}

.contentTitle {
  font-size: 24px;
  line-height: 1em;
  font-weight: var(--fontWeightMedium);
  color: var(--colorWhite);
}

.contentDescription {
  font-size: 14px;
  line-height: 1.1em;
  font-weight: var(--fontWeightRegular);
  color: var(--colorWhite);
  margin-top: 20px;
  font-family: Helvetica, sans-serif;
}
