@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 2px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    padding: 0 60px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 120px;
  }

  @media (--viewportXLarge) {
    padding: 0 calc((100% - 1440px) / 2);
  }
}

.root div[class^='SectionContainer_sectionContent'] {
  @media (--viewportMedium) {
    padding: 32px 0 0;
  }
}

.baseColumn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
  justify-content: center;
}

.logoColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    align-items: center;
    gap: 50px;
    grid-template-columns: repeat(3, calc((100% - 2 * 50px) / 5));
  }

  @media (--viewportLargeWithPaddings) {
    align-items: center;
    gap: 75px;
    grid-template-columns: repeat(6, calc((100% - 5 * 75px) / 6));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
