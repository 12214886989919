.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 32px;
}

.readMoreLink {
  margin-top: 24px;
  color: var(--colorBlack);
}
